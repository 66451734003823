import request from '@/utils/request'

// 外部报表 report

// 后勤模板 logistics
// 后勤模板 logistics
// 后勤模板 logistics
/**
 * 获取后勤模板分页列表
 */
export const logisticsTemplatePageList = params => {
  return request({
    url: '/logistics-template/page-list',
    method: 'GET',
    params
  })
}
/**
 * 获取后勤模板列表
 */
export const logisticsTemplateList = params => {
  return request({
    url: '/logistics-template/list',
    method: 'GET',
    params
  })
}
/**
 * 获取后勤模板详情
 */
export const logisticsTemplateDetail = params => {
  return request({
    url: '/logistics-template/detail/get',
    method: 'GET',
    params
  })
}
/**
 * 增加后勤模板
 */
export const logisticsTemplateAdd = data => {
  // template_name 模板名称
  // invalid_order_status[] 无效订单状态
  // expected_express_fee 预计快递费
  // expected_labor_cost 预计人工费
  return request({
    url: '/logistics-template/add',
    method: 'POST',
    data
  })
}
/**
 * 删除后勤模板
 */
export const logisticsTemplateDel = data => {
  return request({
    url: '/logistics-template/delete',
    method: 'POST',
    data
  })
}
// SKU成本 SKU COST
// SKU成本 SKU COST
// SKU成本 SKU COST
/**
 * 获取SKU成本表分页列表
 */
export const skuCostPageList = params => {
  return request({
    url: '/sku-cost/page-list',
    method: 'GET',
    params
  })
}
/**
 * 获取SKU成本表列表
 */
export const skuCostList = params => {
  return request({
    url: '/sku-cost/list',
    method: 'GET',
    params
  })
}
/**
 * 获取SKU成本表详情
 */
export const skuCostDetail = params => {
  return request({
    url: '/sku-cost/detail/get',
    method: 'GET',
    params
  })
}
/**
 * 增加SKU成本表
 */
export const skuCostAdd = data => {
  return request({
    url: '/sku-cost/add',
    method: 'POST',
    data
  })
}
/**
 * 删除SKU成本表
 */
export const skuCostDel = data => {
  return request({
    url: '/sku-cost/delete',
    method: 'POST',
    data
  })
}
// 报表分析 Report Analysis
// 报表分析 Report Analysis
// 报表分析 Report Analysis
/**
 * 获取报表分析分页列表
 */
export const reportAnalysisPageList = params => {
  return request({
    url: '/statement-analysis/page-list',
    method: 'GET',
    params
  })
}
/**
 * 获取报表分析详情
 */
export const reportAnalysisDetail = params => {
  return request({
    url: '/statement-analysis/detail/get',
    method: 'GET',
    params
  })
}
/**
 * 增加报表分析
 */
export const reportAnalysisAdd = data => {
  return request({
    url: '/statement-analysis/add',
    method: 'POST',
    data
  })
}
/**
 * 删除报表分析
 */
export const reportAnalysisDel = data => {
  return request({
    url: '/statement-analysis/delete',
    method: 'POST',
    data
  })
}
